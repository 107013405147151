import styled from "styled-components";
import { StreamFlavor } from "@sablier/v2-constants/stream";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IFlow, ILockup } from "@sablier/v2-models/types";
import { Card, Chart } from "~/client/components/molecules";

const WrapperPartial = styled(Card)`
  & > * {
    width: ${(props) => props.theme.sizes.streamDetailsWidth};
    border: 2px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.modalDetailsBackground};
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    height: 340px;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    & > * {
      width: 100%;
      max-width: calc(100vw - ${(props) => props.theme.sizes.edge});
    }
  }
`;

interface Props {
  stream: ILockup | IFlow | undefined;
}

function Emission({ stream }: Props) {
  const { t } = useT();

  if (_.isNil(stream)) {
    return false;
  }

  if (stream.flavor === StreamFlavor.Flow) {
    return false; // TODO enable this once flow has a simulator
  }

  return (
    <Wrapper title={t("words.emission")}>
      <List>
        <Chart stream={stream} />
      </List>
    </Wrapper>
  );
}

export default Emission;
