import styled from "styled-components";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Card } from "~/client/components/molecules";
import type { PropsWithChildren } from "react";

const WrapperPartial = styled(Card)`
  & > * {
    width: ${(props) => props.theme.sizes.streamDetailsWidth};
    border: 2px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.modalDetailsBackground};
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    & > * {
      width: 100%;
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${List} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }
`;

export default function Characteristics({ children }: PropsWithChildren<{}>) {
  const { t } = useT();
  return (
    <Wrapper title={_.capitalize(t("words.overview"))}>
      <List>{children}</List>
    </Wrapper>
  );
}
