import { useMemo } from "react";
import styled from "styled-components";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@sablier/v2-components/atoms";
import { Button } from "@sablier/v2-components/molecules";
import { StreamFlavor, links } from "@sablier/v2-constants";
import { useChainData, useWindowSize } from "@sablier/v2-hooks";
import useRequestNFT from "@sablier/v2-hooks/useRequestNFT";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { IFlow, ILockup } from "@sablier/v2-models/types";
import { getTheme } from "@sablier/v2-themes";
import Image from "next/image";
import { Card } from "~/client/components/molecules";

const theme = getTheme();

const WrapperPartial = styled(Card)`
  & > * {
    width: ${(props) => props.theme.sizes.streamDetailsWidth};
    border: 2px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.modalDetailsBackground};
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray200};
      line-height: 20pt;
    }
  }
`;

const Showcase = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 100%;
    height: 500px;
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.dark050};
    overflow: hidden;
  }
`;

const Holder = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const Floater = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:nth-child(1) {
    z-index: 10;
    width: 440px;
    height: 440px;
    img {
      border-radius: 26px;
    }
  }

  &:nth-child(2) {
    z-index: 5;
    img {
      filter: blur(20px);
      object-fit: cover;
    }
  }
`;

const Fixer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > * {
    z-index: 10;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Loader = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
  }
`;

const Lights = styled.div<{ accent?: string }>`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0.2;

  &:before,
  &:after {
    position: absolute;
    content: "";
    width: 200px;
    height: 200px;
    border-radius: 50px;
    background-color: ${(props) => props.accent || props.theme.colors.dark300};
    filter: blur(50px);
  }

  &:before {
    top: -100px;
    left: -100px;
  }

  &:after {
    bottom: -100px;
    right: -100px;
  }
`;

const Lines = styled.div<{ accent?: string }>`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0.2;

    &:before {
      top: 30px;
    }

    &:after {
      bottom: 30px;
    }

    &::before,
    &:after {
      position: absolute;
      content: "";
      width: 90%;
      height: 10px;
      margin: 0 auto;
      background-color: ${(props) => props.accent || props.theme.colors.white};
      filter: blur(20px);
    }
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    margin-left: auto;
  }
`;

const Note = styled.div`
  width: 100%;
  padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  border-left: 2px solid ${(props) => props.theme.colors.yellow};
  background-color: ${(props) => props.theme.colors.yellow10};
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    & > * {
      width: 100%;
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    ${Showcase} {
      height: calc(100vw - 2 * 24px);
      ${Floater} {
        &:nth-child(1) {
          width: calc(100vw - 2 * 40px);
          height: calc(100vw - 2 * 40px);
          img {
            border-radius: 12px;
          }
        }
      }
    }
  }
`;

interface Props {
  stream: ILockup | IFlow | undefined;
}

function Art({ stream }: Props) {
  const { t } = useT();
  const { chain } = useChainData(stream?.chainId);
  const accent = useMemo(() => stream?.findAccent(), [stream]);
  const { isLoading, value: asset } = useRequestNFT({ stream });
  const { width } = useWindowSize();

  const source = useMemo(() => {
    if (_.isNilOrEmptyString(asset) || asset.includes("<script")) {
      return "";
    }
    const prefix = "data:image/svg+xml;charset=utf-8,";
    if (width < parseInt(theme.sizes.deviceSM)) {
      const frozen = asset.replaceAll("animate", "a");
      return `${prefix}${encodeURIComponent(frozen)}`;
    }
    return `${prefix}${encodeURIComponent(asset)}`;
  }, [asset, width]);

  return (
    <Wrapper
      title={t("words.nft").toUpperCase()}
      extra={
        <Actions>
          <Button
            accent={"iconic"}
            appearance={"outline"}
            isMini
            title={"OpenSea"}
            right={ArrowUpRightIcon}
            purpose={"external"}
            to={links.nft.opensea(
              chain?.name.toLowerCase(),
              stream?.contract,
              stream?.tokenId,
            )}
          />
        </Actions>
      }
    >
      <List>
        {stream?.flavor === StreamFlavor.Lockup && (
          <>
            <Description>
              <p>
                {t(
                  stream?.isTransferable
                    ? "descriptions.aboutNFTs.transferable.on"
                    : "descriptions.aboutNFTs.transferable.off",
                )}
              </p>
            </Description>
            {!stream?.isTransferable ? (
              <Note>
                <p>{t("descriptions.nonTransferableNFT")}</p>
              </Note>
            ) : (
              false
            )}
          </>
        )}
        <Showcase>
          {isLoading ? (
            <Loader>
              <Spinner color={"white"} />
            </Loader>
          ) : (
            <Holder>
              <Floater>
                <Fixer>
                  <Image
                    alt={"NFT"}
                    fill
                    quality={100}
                    sizes={"(max-width: 600px) 90vw, 40vw"}
                    src={source}
                  />
                </Fixer>
              </Floater>

              <Floater>
                <Lights accent={accent} />
                <Lines />
              </Floater>
            </Holder>
          )}
        </Showcase>
      </List>
    </Wrapper>
  );
}

export default Art;
