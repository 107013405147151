import { useMemo } from "react";
import { QUERY_CACHE_TIME, REQUEST_ID } from "@sablier/v2-constants";
import { framework } from "@sablier/v2-contracts";
import { _ } from "@sablier/v2-mixins";
import { IWagmiConfig } from "@sablier/v2-types";
import { useQuery } from "@tanstack/react-query";
import { useConfig } from "wagmi";
import type { Output } from "@sablier/v2-contracts";
import type { IStream } from "@sablier/v2-models";

interface Props {
  stream?: IStream;
}

export async function request({
  library,
  stream,
}: Props & { library: IWagmiConfig }): Promise<string> {
  try {
    if (_.isNil(stream) || _.isNilOrEmptyString(stream.tokenId)) {
      throw new Error("Missing stream.");
    }

    const query = framework.contextualize(
      stream.contract,
      stream.chainId,
      stream.category,
      "tokenURI",
      [_.toBigInt(stream.tokenId)],
    );

    const previews = await framework.preview({ queries: [query] });
    const results = await framework.read(library, { previews });

    const output = results[0].result as Output<
      typeof stream.category,
      "tokenURI"
    >;

    const toPart = output.split("data:application/json;base64,").pop();
    const toString = Buffer.from(toPart || "", "base64").toString("utf-8");
    const toJSON = JSON.parse(toString);

    const blob = _.get(toJSON, "image")?.split("data:image/svg+xml;base64,")[1];
    const toSVG = Buffer.from(blob || "", "base64").toString("utf-8");

    return toSVG;
  } catch (error) {
    throw new Error("Failed to extract tokenURI.");
  }
}

export default function useRequestNFT({ stream }: Props) {
  const library = useConfig();

  const isEnabled = useMemo(
    () =>
      !_.isNilOrEmptyString(stream?.tokenId) &&
      !_.isNilOrEmptyString(stream?.contract),
    [stream?.tokenId, stream?.contract],
  );

  const {
    data: value,
    error,
    isLoading,
  } = useQuery<string | undefined>({
    queryKey: [...REQUEST_ID.streamItemNFT, { unique: { id: stream?.id } }],
    queryFn: async () => request({ library, stream }),
    staleTime: QUERY_CACHE_TIME,
    gcTime: QUERY_CACHE_TIME,
    retry: false,
    enabled: isEnabled,
  });

  return useMemo(
    () => ({
      error,
      isLoading,
      value,
      request,
    }),
    [error, isLoading, value],
  );
}
