import { ReactNode } from "react";
import styled from "styled-components";
import { Label } from "@sablier/v2-components/molecules";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Card } from "~/client/components/molecules";

const WrapperPartial = styled(Card)`
  & > * {
    width: ${(props) => props.theme.sizes.streamDetailsWidth};
    border: 2px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.modalDetailsBackground};
  }
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
`;

const Timelines = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 4);
    width: 100%;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    & > * {
      width: 100%;
    }
    ${List} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
      padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
    ${Grid} {
      grid-template-columns: 1fr;
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      br {
        display: none;
      }
    }
  }
`;

interface Props {
  amounts?: ReactNode | undefined;
  timelines?: ReactNode | undefined;
}

export default function Accounting({ amounts, timelines }: Props) {
  const { t } = useT();
  return (
    <Wrapper title={t("words.accounting")}>
      <List>
        <Label value={t("words.amounts")} />
        <Grid>{amounts}</Grid>
        <Divider />
        <Label value={t("words.timeline")} />
        <Timelines>{timelines}</Timelines>
      </List>
    </Wrapper>
  );
}
