import styled from "styled-components";
import { Table } from "@sablier/v2-components/organisms";
import { useLockupCSVTable } from "~/client/hooks";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    --size: 16px;
    --gap: 6px;
    --dashboard-column-index: 32px;
    --dashboard-column-valid: 80px;
    --dashboard-column-address: 240px;
    --dashboard-column-address-small: 120px;
    --dashboard-column-amount: 150px;
    --dashboard-column-3: 1fr;
    --dashboard-column-4: 1fr;
    --dashboard-column-5: 1fr;
    --dashboard-column-6: 1fr;
    --dashboard-column-7: 1fr;
    --dashboard-column-8: 1fr;
    --dashboard-column-9: 1fr;
    --dashboard-column-more: minmax(90px, auto);
    --dashboard-column-duration: 1fr;
    --dashboard-column-date: 150px;

    gap: ${(props) => props.theme.sizes.edge};
    width: 100%;
    max-width: 100%;
    padding-top: ${(props) => props.theme.sizes.edge};
    overflow: hidden;

    p,
    label,
    span {
      font-size: 11pt;
    }
    label {
      line-height: 16px;
    }

    *[data-component="header"] {
      gap: 2px;
      height: ${(props) => props.theme.sizes.tableRowSlim}px;
      padding-inline: 0px;

      p {
        font-weight: 600;
        font-size: 12pt;
        letter-spacing: normal;
        text-transform: capitalize;
      }
    }
    *[data-component="body"] {
      border: 2px solid ${(props) => props.theme.colors.border};
      background-color: transparent;
    }
    *[data-component="body"] *[data-component="row"] {
      gap: 2px;
      padding-inline: 0px;
      background-color: transparent;
      &:hover,
      &:active {
        background-color: transparent;
        cursor: default;
      }
      & > *:nth-child(2) {
        p {
          ${(props) => props.theme.styles.ellipsis}
          & {
            max-width: min(28vw, 380px);
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.betweenLGAndContainer} {
    *[data-component="body"] *[data-component="row"] {
      & > *:nth-child(2) {
        p {
          ${(props) => props.theme.styles.ellipsis}
          & {
            max-width: min(28vw, 380px);
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.max(props.theme.sizes.deviceMD)} {
    *[data-component="table"] {
      *[data-component="header"] {
        display: none;
      }
      *[data-component="body"] {
        padding: calc(${(props) => props.theme.sizes.edge} * 1 / 2 - 2px);
        border: 2px solid ${(props) => props.theme.colors.border};
        background-color: ${(props) => props.theme.colors.dark100};
        & > *[data-component="list"] {
          grid-template-columns: 1fr 1fr;
          grid-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2 - 2px);
          max-width: 100%;

          *[data-component="row"] {
            grid-column: span 1;
            position: relative;
            display: flex;
            flex-direction: column;
            grid-template-columns: 1fr;
            grid-gap: calc(${(props) => props.theme.sizes.edge} * 1);
            height: auto;
            padding-inline: calc(${(props) => props.theme.sizes.edge} * 1);
            padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
            border: 2px solid ${(props) => props.theme.colors.border};
            border-radius: 6px;

            &:hover,
            &:active,
            &:focus {
              background-color: transparent;
            }

            & > * {
              width: auto;
              height: auto;
              max-width: 100%;
              padding-inline: calc(${(props) => props.theme.sizes.edge} * 0);
            }

            & > *:nth-child(1) {
              ${(props) => props.theme.styles.row}
              & {
                justify-content: flex-start;
                padding-top: calc(${(props) => props.theme.sizes.edge} * 1);
                padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
                border-bottom: 2px solid ${(props) => props.theme.colors.border};
              }
            }

            & > *:nth-child(2) {
              p {
                ${(props) => props.theme.styles.ellipsis}
                & {
                  width: 100%;
                  max-width: calc(
                    (100vw - 2 * (${(props) => props.theme.sizes.edge} * 4)) / 2
                  );
                }
              }
            }
          }
        }
      }
    }
  }

  ${(props) => props.theme.medias.maxSM} {
    *[data-component="table"] {
      *[data-component="body"] {
        & > *[data-component="list"] {
          grid-template-columns: 1fr;
          *[data-component="row"] {
            & > *:nth-child(2) {
              p {
                max-width: calc(
                  100vw - 2 * (${(props) => props.theme.sizes.edge} * 2)
                );
              }
            }
          }
        }
      }
    }
  }
`;

function CSVTable({ isFull = false }: { isFull?: boolean }) {
  const data = useLockupCSVTable(isFull);

  return (
    <Wrapper>
      <Table data={data} />
    </Wrapper>
  );
}

export default CSVTable;
