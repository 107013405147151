import styled from "styled-components";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import { Card as Base } from "~/client/components/molecules";
import type { PropsWithChildren } from "react";

const WrapperPartial = styled(Base)`
  & > * {
    width: ${(props) => props.theme.sizes.streamDetailsWidth};
    border: 2px solid ${(props) => props.theme.colors.border};
    background-color: ${(props) => props.theme.colors.modalDetailsBackground};
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);
    width: 100%;
    padding-top: calc(${(props) => props.theme.sizes.formEdge} * 3 / 4);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
  column-gap: calc(${(props) => props.theme.sizes.edge} * 1);

  & > div {
    grid-column: span 1;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    width: 100%;
    & > * {
      width: 100%;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Grid} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Grid} {
      grid-template-columns: 1fr;
    }
  }
`;

export default function Actions({ children }: PropsWithChildren<{}>) {
  const { t } = useT();
  return (
    <Wrapper title={_.capitalize(t("words.actions"))}>
      <Content>
        <Grid>{children}</Grid>
      </Content>
    </Wrapper>
  );
}

export function Loading() {
  return (
    <Wrapper isTopHidden={"preserve"}>
      <Content data-loading={true} />
    </Wrapper>
  );
}
